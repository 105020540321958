import { zodResolver } from "@hookform/resolvers/zod";
import {
  AttractionGroup,
  useGetAttraction,
  useOpened,
} from "@twocontinents/dashboard/shared";
import { DateFormatter, uniqueArray } from "@twocontinents/shared";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  ExtraOptionAvailability as ExtraOptionAvailabilityResponse,
  useGetExtraOptionAvailabilities,
  useUpdateAttractionGroupAvailabilities,
} from "../../../../data-access";

const ExtraOptionsAvailabilityFormSchema = z.object({
  extraOptionLocaleGroupId: z.string(),
  date: z.string(),
  available: z.boolean(),
  availabilities: z
    .object({
      date: z.string(),
      groupId: z.number(),
      available: z.boolean(),
    })
    .array(),
  times: z.coerce.number().optional(),
  days: z.coerce.number().optional(),
});

export type ExtraOptionsAvailabilityForm = z.infer<
  typeof ExtraOptionsAvailabilityFormSchema
>;

export type CalendarExtraOptionAvailability =
  ExtraOptionsAvailabilityForm["availabilities"][number] & {
    localeGroupId: string;
    id?: number;
  };

export const useExtraOptionsAvailabilitySettings = (
  attractionGroups: AttractionGroup[],
  attractionId: number,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const { opened: activationLogsOpened, toggle: toggleActivationLogsOpened } =
    useOpened(false);
  const [mutatedAvailabilities, setMutatedAvailabilities] = useState<
    CalendarExtraOptionAvailability[]
  >([]);

  const { updateGroupAvailability, isPending } =
    useUpdateAttractionGroupAvailabilities();

  const { attraction } = useGetAttraction(attractionId);
  const extraOptions =
    attraction?.extraOptions?.toSorted((a, b) => a.id - b.id) ?? [];

  const distinctLocaleGroupIds = uniqueArray(
    extraOptions.map((option) => option.localeGroupId),
  );

  const form = useForm<ExtraOptionsAvailabilityForm>({
    resolver: zodResolver(ExtraOptionsAvailabilityFormSchema),
    defaultValues: {
      times: 1,
      days: 1,
      date: "",
    },
  });

  const selectedDate = form.watch("date");
  const selectedLocaleGroupId = form.watch("extraOptionLocaleGroupId");

  const selectedExtraOptions = extraOptions.filter(
    (extraOption) => extraOption.localeGroupId === selectedLocaleGroupId,
  );
  const selectedExtraOptionIds = selectedExtraOptions.map(
    (option) => option.id,
  );

  const attractionGroupsOfSelectedExtraOption = attractionGroups.filter(
    (group) =>
      group.attractionVariants.some((variant) =>
        variant.extraOptions.some(
          (option) => option.localeGroupId === selectedLocaleGroupId,
        ),
      ),
  );

  const groupIds = attractionGroupsOfSelectedExtraOption.map(
    (group) => group.id,
  );

  const formRef = useRef<HTMLFormElement | null>(null);

  const { handleSubmit, watch, setValue } = form;

  const resetFormToDefaults = () => {
    setValue("date", "");
    setValue("times", 1);
    setValue("days", 1);
  };

  const onSubmit = handleSubmit(({ times = 1, days = 1, available }) => {
    const availabilities = generateAvailabilitiesInInterval(
      selectedLocaleGroupId,
      available,
      selectedDate,
      groupIds,
      times,
      days,
    );

    setMutatedAvailabilities((prev) => {
      const previousNotOverlappingAvailabilities = prev.filter(
        (prevAvailability) =>
          !availabilities.some(
            (availability) =>
              prevAvailability.groupId === availability.groupId &&
              prevAvailability.date === availability.date,
          ),
      );
      return [...previousNotOverlappingAvailabilities, ...availabilities];
    });

    resetFormToDefaults();
  });

  const onSubmitUnavailable = () => {
    setValue("available", false);
    formRef.current?.requestSubmit();
  };

  const onSubmitAvailable = () => {
    setValue("available", true);
    formRef.current?.requestSubmit();
  };

  const date = watch("date");
  const times = watch("times");

  const { availabilities } = useGetExtraOptionAvailabilities(
    selectedExtraOptionIds,
  );

  const showSubmitButtons = form.formState.isValid;
  const saveMutatedAvailabilities = () => {
    const uniqueGroupIds = uniqueArray(
      mutatedAvailabilities.map((av) => av.groupId),
    );

    uniqueGroupIds.forEach((groupId) => {
      const availabilities = mutatedAvailabilities.filter(
        (av) => av.groupId === groupId,
      );
      const extraOptions = selectedExtraOptions.map((option) => option.id);
      extraOptions.forEach((extraOptionId) => {
        updateGroupAvailability({
          attractionId,
          groupId,
          body: {
            extraOptionAvailabilities: availabilities.map((av) => ({
              ...av,
              extraOptionId,
            })),
            activationLogs: [],
            availabilities: [],
          },
        });
      });
    });

    setMutatedAvailabilities([]);
  };

  const addButtonDisabled = selectedExtraOptions.length === 0 || !selectedDate;

  useEffect(() => {
    setMutatedAvailabilities([]);
  }, [selectedLocaleGroupId]);

  useEffect(() => {
    const allAvailabilities = mergeFetchedAndMutatedAvailabilities(
      mutatedAvailabilities,
      availabilities,
      selectedLocaleGroupId,
    );
    const selectedAvailabilities = allAvailabilities.filter(
      (availability) => availability.date === selectedDate,
    );
    setValue("availabilities", selectedAvailabilities);
  }, [
    availabilities,
    date,
    mutatedAvailabilities,
    selectedDate,
    selectedExtraOptions,
    setValue,
    selectedLocaleGroupId,
  ]);

  return {
    form,
    onSubmit,
    isPending,
    times,
    extraOptions,
    mutatedAvailabilities,
    saveMutatedAvailabilities,
    onSubmitUnavailable,
    showSubmitButtons,
    formRef,
    activationLogsOpened,
    toggleActivationLogsOpened,
    addButtonDisabled,
    attractionGroupsOfSelectedExtraOption,
    distinctLocaleGroupIds,
    onSubmitAvailable,
    allAvailabilities: mergeFetchedAndMutatedAvailabilities(
      mutatedAvailabilities,
      availabilities,
      selectedLocaleGroupId,
    ),
    selectedLocaleGroupId,
    selectedExtraOptions,
  };
};

const generateAvailabilitiesInInterval = (
  localeGroupId: string,
  available: boolean,
  date: string,
  groupIds: number[],
  times: number,
  days: number,
) => {
  const initialDate = dayjs(date);

  const availabilities: CalendarExtraOptionAvailability[] = [];

  for (let i = 0; i < times; i++) {
    const newDate = initialDate.add(days * i, "day");
    const formattedDate = DateFormatter.formatToYYYYMMDD(newDate);
    groupIds.forEach((groupId) => {
      availabilities.push({
        available,
        groupId,
        date: formattedDate,
        localeGroupId,
      });
    });
  }

  return availabilities;
};

const mergeFetchedAndMutatedAvailabilities = (
  mutatedAvailabilities: CalendarExtraOptionAvailability[],
  fetchedAvailabilities: ExtraOptionAvailabilityResponse[],
  selectedLocaleGroupId: string,
) => {
  return [
    ...mutatedAvailabilities
      .map((availability) => ({
        groupId: availability.groupId,
        available: availability.available,
        date: availability.date,
        localeGroupId: availability.localeGroupId,
      }))
      .filter(
        (availability) => availability.localeGroupId === selectedLocaleGroupId,
      ),
    ...fetchedAvailabilities
      .filter((av) => av.localeGroupId === selectedLocaleGroupId)
      .map((availability) => ({
        groupId: availability.groupId,
        available: availability.available,
        date: availability.date,
        localeGroupId: availability.localeGroupId,
        id: availability.id,
      })),
  ];
};
